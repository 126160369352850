<template>
  <router-link class="WorkticketItem" :to="routerLinkSwitch">
    <div class="name-line">
      <span>【{{ status }}】</span>
      <span>{{ title }}</span>
      <span>（{{ types }}）</span>
    </div>
    <!-- <div class="number">编号：{{ number }}</div> -->

    <div class="marginBottom">
      <span class="icon-item "
        ><i
          :style="
            `background-image: url(${require('@/assets/images/workticket-item-1.png')})`
          "
        ></i
        >{{ text1 }}</span
      >
      <span class="icon-item"
        ><i
          :style="
            `background-image: url(${require('@/assets/images/workticket-item-4.png')})`
          "
        ></i
        >{{ text2 }}</span
      >
    </div>

    <div class="marginBottom">
      <span class="icon-item"
        ><i
          :style="
            `background-image: url(${require('@/assets/images/workticket-item-2.png')})`
          "
        ></i
        >{{ text3 }}</span
      >
      <!-- <span class="icon-item"
        ><i
          :style="
            `background-image: url(${require('@/assets/images/workticket-item-5.png')})`
          "
        ></i
        >{{ text4.toString() }}</span
      > -->
      <span class="icon-item"
        ><i
          :style="
            `background-image: url(${require('@/assets/images/workticket-item-3.png')})`
          "
        ></i
        >{{ startTime }}</span
      >
    </div>

    <!-- <div>
      <span class="icon-item"
        ><i
          :style="
            `background-image: url(${require('@/assets/images/workticket-item-3.png')})`
          "
        ></i
        >{{ startTime }} 至 {{ endTime }}</span
      >
    </div> -->
  </router-link>
</template>

<script>
export default {
  components: {},
  props: {
    id: { type: [String, Number], default: "" },
    status: { type: String, default: "作业状态" },
    title: { type: String, default: "作业名称" },
    types: { type: String, default: "作业类别" },
    number: { type: String, default: "" }, //许可编号
    startTime: { type: String, default: "作业状态" },
    endTime: { type: String, default: "作业状态" },
    text1: { type: String, default: "" },
    text2: { type: String, default: "" },
    text3: { type: String, default: "" },
    text4: { type: Array, default: () => [] },
    routerLink: { type: Boolean, default: true }
  },
  data() {
    return {};
  },
  computed: {
    routerLinkSwitch() {
      if (!this.routerLink) return "";
      return `/workticket/${this.id}`;
    }
  },
  created: function() {},
  mounted: function() {},
  methods: {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.WorkticketItem {
  flex: 1;
  display: block;
  padding: 10px 16px;
  background-color: #fff;
  font-size: 12px;
  color: #3b4664;
  line-height: 17px;
  .name-line {
    font-size: 14px;
    line-height: 20px;
    font-weight: bold;
    word-break: break-all;
  }

  .number {
    color: #8c8f97;
    margin: 5px 0px;
    // margin: 5px 0px 0;
  }

  .marginBottom {
    margin-bottom: 5px;
  }

  .icon-item {
    display: inline-block;
    margin-right: 20px;

    i {
      display: inline-block;
      width: 15px;
      height: 15px;
      vertical-align: top;
      margin-right: 5px;
      background-size: cover;
    }
  }
}
</style>
