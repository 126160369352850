<template>
  <div>
    <van-nav-bar
      title="作业活动管理"
      fixed
      left-arrow
      @click-left="$router.back()"
    >
    </van-nav-bar>
    <workBeforeSafetyAnalyze
      ref="workJsa"
      class="jsa-box"
      is-source
      is-select
      :default-query="{ status: '2,3' }"
      query-type="workTemp"
      @click="jsaClick"
    />
    <bottom-fixed-box>
      <van-button class="btn-default" @click="cancel">取消</van-button>
      <van-button class="btn-primary" @click="confirm">确认</van-button>
    </bottom-fixed-box>
  </div>
</template>

<script>
import workBeforeSafetyAnalyze from "@/views/workticket/workBeforeSafetyAnalyze/workBeforeSafetyAnalyze";
import BottomFixedBox from "@/components/general/BottomFixedBox";

export default {
  components: {
    workBeforeSafetyAnalyze,
    BottomFixedBox
  },
  data() {
    return {
      selected: ""
    };
  },
  computed: {},
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  methods: {
    jsaClick(val) {
      this.selected = val;
    },
    cancel() {
      this.$router.back();
    },
    confirm() {
      if (this.selected) {
        window.sessionStorage.setItem(
          "tempByAdd",
          JSON.stringify(this.selected)
        );
      }
      this.cancel();
    }
  }
};
</script>

<style lang="scss" scoped>
.tabs-box {
  margin: 58px 0 0;
  .jsa-box {
    padding: 0;
  }
}
</style>
