import { render, staticRenderFns } from "./WBSAWorkQuery.vue?vue&type=template&id=185c19fc&scoped=true&"
import script from "./WBSAWorkQuery.vue?vue&type=script&lang=js&"
export * from "./WBSAWorkQuery.vue?vue&type=script&lang=js&"
import style0 from "./WBSAWorkQuery.vue?vue&type=style&index=0&id=185c19fc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "185c19fc",
  null
  
)

export default component.exports