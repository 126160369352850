<template>
  <div class="has-nav-bar view-workticket">
    <van-nav-bar
      v-if="!isSelect"
      title="作业活动管理"
      fixed
      left-arrow
      @click-left="$router.back()"
    >
      <template #right>
        <!-- v-if="btnAuth.add" -->
        <van-icon
          size="24"
          name="iconfont-xinzengfengxian"
          class-prefix="iconfont"
          class="iconfont-xinzengfengxian"
          @click="addWBSA()"
        />
      </template>
    </van-nav-bar>
    <div>
      <van-search
        v-model="query.jsaName"
        :placeholder="`请输入${projectTextConfig.TEXT_JSA_NAME}`"
        @search="getList(true)"
        @clear="getList(true)"
      />
      <date-time
        v-model="dateTime"
        :time-close="false"
        type="date"
        format="{y}-{m}-{d}"
        @input="getList(true)"
      />
      <WBSA-Work-Query
        :query="query"
        :query-type="queryType"
        @refresh="onQueryChange"
      ></WBSA-Work-Query>
    </div>
    <LoadListView
      v-model="loadStatus"
      :list="list"
      :total="total"
      @load="getList"
    >
      <div
        v-for="(item, index) in list"
        :key="index"
        class="item-box block-space_8"
      >
        <div v-if="isSelect" class="radio-box">
          <van-radio-group v-model="radio" icon-size="18">
            <van-radio :name="item" @click="radioClick(item)"></van-radio>
          </van-radio-group>
        </div>
        <WBSA-WorkticketItem
          :id="item.id"
          :router-link="false"
          :title="item.jsaName"
          :number="item.jsaCode"
          :status="item.statusName"
          :types="item.workTypeNames"
          :start-time="item.analysisDate"
          :text1="item.departmentName"
          :text2="item.facilityName"
          :text3="item.analyst.name"
          @click.native="toDetail(item)"
        />
      </div>
    </LoadListView>
  </div>
</template>

<script>
import { mapState } from "vuex";
import projectTextConfig from "@/utils/projectTextConfig";
import WBSAWorkticketItem from "@/views/workticket/components/WBSAWorkticketItem";
import LoadListView from "@/components/LoadListView";
import { getWorkJsaByPage } from "@/api/workticket";
// import { getTimeParseDate } from "@/utils";
import DateTime from "@/components/DatetimePicker";
import WBSAWorkQuery from "@/views/workticket/components/WBSAWorkQuery";

export default {
  name: "WorkBeforeSafetyAnalyze",
  props: {
    isSelect: {
      type: Boolean,
      default: false
    },
    isSource: {
      type: Boolean,
      default: false
    },
    defaultQuery: {
      type: Object,
      default: () => ({})
    },
    queryType: {
      type: String,
      default: "job"
    }
  },
  components: {
    WBSAWorkQuery,
    DateTime,
    LoadListView,
    WBSAWorkticketItem
  },
  data() {
    return {
      projectTextConfig,
      dateTime: {
        start: "",
        end: ""
      },
      loadStatus: "loading",
      list: [],
      total: 0,
      query: {
        jsaName: "",
        beginTime: "",
        endTime: "",
        workType: "",
        status: "0,1,2,3,5",
        department: "",
        facilityId: "", //作业区域
        facilityType: "",
        facilityName: "",
        // analyst: "", // 分析人
        uuserId: "", // 更新人
        orgCode: this.$store.state.login.userInfo.orgCode,
        size: 10,
        page: 1,
        privilege: true,
        ...this.defaultQuery
      },
      departmentLabel: "",
      areaLabel: "",
      typeLabel: "",
      statusLabel: "",
      departmentVisible: false,
      radio: ""
    };
  },
  computed: {
    ...mapState({
      // userInfo: state => state.login.userInfo,
      btnAuth: state => state.menu.btnAuth
    }),
    workTypeOptions() {
      return this.$store.state.workticket.workTypeOptions;
    },
    workStatusOptions() {
      return this.$store.state.workticket.workStatusOptions;
    },
    userInfo() {
      return this.$store.state.login.userInfo;
    }
  },
  created: function() {},
  mounted: function() {
    if (this.isSource) {
      this.pageInit();
    }
  },
  activated() {
    // 返回当前页面触发刷新
    this.pageInit();
  },
  methods: {
    pageInit() {
      this.getList(true);
      this.$store.dispatch("getWorkTypeOptions");
      this.$store.dispatch("getWorkStatusOptions");
    },
    onQueryChange(query) {
      this.query = { ...this.query, ...query };
      this.getList(true);
    },
    /**
     * 是否是刷新,重新加载第一页
     * @param refresh
     */
    getList(refresh = false) {
      if (refresh) {
        this.query.page = 1;
        this.list = [];
        this.loadStatus = "loading";
      }

      getWorkJsaByPage({
        ...this.query,
        orgCode: this.userInfo.orgCode,
        beginTime: this.dateTime.start || "",
        endTime: this.dateTime.end || ""
      })
        .then(({ total, list }) => {
          const obj = {
            0: "待提交",
            1: "待签名",
            2: "待反馈",
            3: "已完成",
            4: "已删除",
            5: "待审批"
          };
          this.list = this.list.concat(
            list.map(i => {
              i.analyst = i.analyst || {};
              i.statusName = obj[i.status] || "";
              i.workTypeNames = i.workTypeList.map(w => w.name).toString();
              return i;
            })
          );
          this.total = total;
          this.loadStatus = "success";
          if (list && list.length > 0) {
            this.query.page++;
          }
        })
        .catch(error => {
          console.log(error);
          this.loadStatus = "error";
        });
    },
    addWBSA() {
      this.$router.push({
        name: "workBeforeSafetyAnalyzeEdit",
        params: {
          type: "add"
        }
      });
    },
    toDetail(row) {
      if (this.isSelect) return;
      this.$router.push({
        name: "workBeforeSafetyAnalyzeEdit",
        params: {
          type: "detail",
          id: row.id
        }
      });
    },
    radioClick(data) {
      this.$emit("click", data);
    },
    clearRadioChoose() {
      this.radio = "";
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.item-box {
  display: flex;
  align-items: center;
  .radio-box {
    padding: 0 0 0 16px;
  }
}
</style>
